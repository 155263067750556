$grid-breakpoints: (
  sm: 576px, // Mobile
  md: 768px, // Tablet Vertical
  lg: 992px, // Tablet Horizontal
  xl: 1200px, // Desktop < Pantallas xxl
);

// Colors Styles ----------------------------------------------------------------

$color-primary:rgb(147, 114, 246);
$color-secondary-red:rgb(236, 136, 140);
$color-secondary-turquoise:rgb(113, 207, 235);
$color-secondary-green:rgb(142, 204, 146);
$color-secondary-purple:rgb(86, 69, 143);
$color-secondary-blue:rgb(114, 92, 191);
$color-secondary-yellow:rgb(254, 205, 49);
$color-success:#ffff;
$color-danger:#ffff;
$color-warning:#ffff;
$color-info:#ffff;

// Texts
$color-text-white:#ffff;
$color-text-blue:#1a56db;
$color-text-pink: #fc26c7;

// Sizes
$padding-small: 5px;
$padding-medium: 10px;
$padding-large: 15px;

$margin-small: 5px;
$margin-medium: 10px;
$margin-large: 15px;

$border-radius:0.5px;

// Text Styles ----------------------------------------------------------------

// Titles:
$text-font-h1:'Custom Semibold';
$text-font-h2:'Custom Semibold';
$text-font-h3:'Custom Semibold';
$text-font-description:'Custom Semibold';
$text-font-form-question:'Custom Semibold';



:root {
  //DOC: Text style configs 1 ----------------------------------------------------------------
  --tema-text-primary: initial;
  --tema-text-secondary: initial;
  --tema-text-success: initial;
  --tema-text-warning: initial;
  --tema-text-error: initial;

  //DOC: Button style configs 1 ----------------------------------------------------------------
  --tema-button-primary: initial;
  --tema-button-primary-light: initial;
  --tema-button-secondary: initial;
  --tema-button-secondary-light: initial;
  --tema-button-success: initial;
  --tema-button-warning: initial;
  --tema-button-error: initial;


  --logo_url: initial;

  --font_primary_url: initial;

}


