@import "varaibles";

.button {
  padding: $padding-medium;
  margin: 24px;
  border-radius: $border-radius;
}

.primary {
  background-color: $color-primary;
  color: white;

  &:hover {
    background-color: adjust-hue($color-primary, 20deg);
  }
}

.secondary {
  background-color: $color-secondary-purple;
  color: white;

  &:hover {
    background-color: adjust-hue($color-secondary-purple, 20deg);
  }
}

.success {
  background-color: $color-success;
  color: white;

  &:hover {
    background-color: adjust-hue($color-success, 20deg);
  }
}

.danger {
  background-color: $color-danger;
  color: white;

  &:hover {
    background-color: adjust-hue($color-danger, 20deg);
  }
}

.button-primary {
  @apply
  text-white
  box-shadow
  h-10
  my-0.5
  rounded-full
  text-[12px]
  bg-[#24B3C7]
  tracking-[3.6px]
  uppercase
  py-1
  min-w-[180px]
  max-w-[220px]
}

.box-shadow {
  box-shadow: 0px 12px 22px #74819F4A;
}
