@import "varaibles";
@import "buttons";
@import "titles";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "buttons";

html, body {
  height: 100%;
  min-height: 100%;
  max-width: 100% !important;
  width: 100% !important;
}
